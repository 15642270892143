
import { Factcheck } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

import * as StatServices from "@/services/StatServices";
import { format } from "date-fns";

@Component
export default class SingleArticleStatsSection extends Vue {
  @Prop({ required: true }) factcheck!: Factcheck;

  interactionTypes = [
    'CLICK',
    'CLICK_TROUGH',
    'FACEBOOK_SHARE',
    'INSTAGRAM_SHARE',
    'LINK_SHARE',
    'TWITTER_SHARE',
    'WHATSAPP_SHARE',
    'SOCIAL_CLICK',
    'FACEBOOK_CLICK_TROUGH',
    'TWITTER_CLICK_TROUGH'
  ]

  stats = {
    clickTroughs: 0,
    clicks: 0,
    allInteractions: [] as any[]
  }

  get totalSharedCount() {
    return this.stats.allInteractions.filter(e => e.type > 1).filter(a => a.type < 7).length || 0;
  }

  get totalSocialClickCount() {
    return this.stats.allInteractions.filter(e => e.type === 7).length
  }

  get facebookClickCount() {
    return this.stats.allInteractions.filter(e => e.type === 8).length;
  }

  get twitterClickCount() {
    return this.stats.allInteractions.filter(e => e.type === 9).length;
  }

  async mounted() {
    this.fetchStats();
  }

  async fetchStats() {
    const stats = await StatServices.GetFactcheckStats(this.factcheck!.id as string);
    //@ts-ignore
    this.stats = stats;
  }

  formatDate(d: string): string {
    return format(new Date(d), "dd-MM-yyyy hh:mm:ss");
  }
}
